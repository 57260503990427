<script setup>
  import { computed, ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { BForm, BButton, BFormGroup, BFormTextarea, BFormInput, BFormCheckboxGroup, BFormCheckbox, BFormRadio, BFormRadioGroup } from 'bootstrap-vue-next';
  import IncludeExclude from './ui/IncludeExclude.vue';

  const { t } = useI18n();

  const props = defineProps({
    languages: {
      type: Array,
      required: true
    },
    audiences: {
      type: Array,
      required: true
    }
  });

  const all_epub_options = ['reflowable', 'fixed_layout'];
  const all_audiobook_options = ['unabridged', 'abridged'];

  const isbn = ref('');
  const title = ref('');
  const description = ref('');
  const include_imprints = ref([]);
  const exclude_imprints = ref([]);
  const include_contributors = ref([]);
  const exclude_contributors = ref([]);
  const include_lists = ref([]);
  const exclude_lists = ref([]);
  const include_awards = ref([]);
  const exclude_awards = ref([]);
  const include_collections = ref([]);
  const exclude_collections = ref([]);
  const include_languages = ref([]);
  const exclude_languages = ref([]);
  const include_audiences = ref([]);
  const exclude_audiences = ref([]);
  const include_categories = ref([]);
  const exclude_categories = ref([]);
  const publication_date_start = ref('');
  const publication_date_end = ref('');
  const creation_date_start = ref('');
  const creation_date_end = ref('');
  const catalogs = ref(['traditional']);
  const format_epub = ref(true);
  const format_pdf = ref(true);
  const format_audiobook = ref(true);
  const epub_options = ref(all_epub_options);
  const audiobook_options = ref(all_audiobook_options);
  const accessibility = ref('all');
  const accessibility_options = ref('accessibility_fully');

  const cleanTextValue = (textValue) => (textValue.value !== '' ? textValue.value : null);

  const cleanArrayValue = (arrayValue) => (arrayValue.value.length > 0 ? arrayValue.value.map((item) => item.id) : null);

  const onSubmit = () => {
    let formData = {
      isbns: cleanTextValue(isbn),
      title: cleanTextValue(title),
      description: cleanTextValue(description),
      imprint_ids: cleanArrayValue(include_imprints),
      excluded_imprint_ids: cleanArrayValue(exclude_imprints),
      author_ids: cleanArrayValue(include_contributors),
      excluded_author_ids: cleanArrayValue(exclude_contributors),
      serie_ids: cleanArrayValue(include_lists),
      excluded_serie_ids: cleanArrayValue(exclude_lists),
      award_ids: cleanArrayValue(include_awards),
      excluded_award_ids: cleanArrayValue(exclude_awards),
      collection_ids: cleanArrayValue(include_collections),
      excluded_collection_ids: cleanArrayValue(exclude_collections),
      languages: cleanArrayValue(include_languages),
      excluded_languages: cleanArrayValue(exclude_languages),
      audiences: cleanArrayValue(include_audiences),
      excluded_audiences: cleanArrayValue(exclude_audiences),
      category_ids: cleanArrayValue(include_categories),
      excluded_category_ids: cleanArrayValue(exclude_categories),
      publication_date_start: cleanTextValue(publication_date_start),
      publication_date_end: cleanTextValue(publication_date_end),
      offer_creation_date_start: cleanTextValue(creation_date_start),
      offer_creation_date_end: cleanTextValue(creation_date_end),
      subsets: catalogs.value,
      book_formats: [],
      epub_options: format_epub.value ? epub_options.value : null,
      audiobook_options: format_audiobook.value ? audiobook_options.value : null,
      accessibilities: accessibility.value === 'accessible' ? accessibility_options.value : null
    };
    let result =  Object.entries(formData).reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {});
    if (result.languages == undefined) {
      result.languages = 'all';
    }
    if (format_epub.value) {
      result.book_formats.push('epub');
    }
    if (format_pdf.value) {
      result.book_formats.push('pdf');
    }
    if (format_audiobook.value) {
      result.book_formats.push('audiobook');
    }

    window.location.href = '/search?' + new URLSearchParams(result).toString();
  };

  const toggle_epub = (value) => {
    if (value) {
      epub_options.value = all_epub_options.slice();
    } else {
      epub_options.value = [];
    }
  };

  const toggle_audiobook = (value) => {
    if (value) {
      audiobook_options.value = all_audiobook_options.slice();
    } else {
      audiobook_options.value = [];
    }
  };

  const accessibility_options_disabled = computed(() => {
    return accessibility.value !== 'accessible';
  });

  watch(epub_options, (newValue) => {
    format_epub.value = newValue.length > 0;
  });

  watch(audiobook_options, (newValue) => {
    format_audiobook.value = newValue.length > 0;
  });
</script>

<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-6">
        <BForm @submit="onSubmit">
          <BFormGroup :label="t('advanced_search.isbns.label')" :description="t('advanced_search.isbns.description')" label-for="isbn">
            <BFormTextarea v-model="isbn" rows="3" id="isbn" />
          </BFormGroup>

          <BFormGroup :label="t('advanced_search.title.label')" class="mt-3" label-for="title">
            <BFormInput v-model="title" id="title" />
          </BFormGroup>

          <BFormGroup :label="t('advanced_search.description.label')" class="mt-3" label-for="description" >
            <BFormInput v-model="description" id="description" />
          </BFormGroup>

          <IncludeExclude object_type="imprint" v-model:inclusions="include_imprints" v-model:exclusions="exclude_imprints" />

          <IncludeExclude object_type="contributor" v-model:inclusions="include_contributors" v-model:exclusions="exclude_contributors" />

          <IncludeExclude object_type="list" v-model:inclusions="include_lists" v-model:exclusions="exclude_lists" />

          <IncludeExclude object_type="award" v-model:inclusions="include_awards" v-model:exclusions="exclude_awards" />

          <IncludeExclude object_type="collection" v-model:inclusions="include_collections" v-model:exclusions="exclude_collections" />

          <IncludeExclude object_type="language" v-model:inclusions="include_languages" v-model:exclusions="exclude_languages" :options="props.languages" />

          <IncludeExclude object_type="audience" v-model:inclusions="include_audiences" v-model:exclusions="exclude_audiences" :options="props.audiences" />

          <IncludeExclude object_type="category" v-model:inclusions="include_categories" v-model:exclusions="exclude_categories" />

          <div class="row">
            <div class="col-6">
              <BFormGroup :label="t('advanced_search.publication_date.label')" class="mt-3" label-for="publication_date_start">
                <div class="row">
                  <div class="col-6">
                    <BFormGroup :label="t('advanced_search.start_date')" label-for="publication_date_start">
                      <BFormInput id="publication_date_start" type="date" v-model="publication_date_start" />
                    </BFormGroup>
                  </div>
                  <div class="col-6">
                    <BFormGroup :label="t('advanced_search.end_date')" label-for="publication_date_end">
                      <BFormInput id="publication_date_end" type="date" v-model="publication_date_end" />
                    </BFormGroup>
                  </div>
                </div>
              </BFormGroup>
            </div>
            <div class="col-6">
              <BFormGroup :label="t('advanced_search.creation_date.label')" class="mt-3" label-for="creation_date_start">
                <div class="row">
                  <div class="col-6">
                    <BFormGroup :label="t('advanced_search.start_date')" label-for="creation_date_start">
                      <BFormInput id="creation_date_start" type="date" v-model="creation_date_start" />
                    </BFormGroup>
                  </div>
                  <div class="col-6">
                    <BFormGroup :label="t('advanced_search.end_date')" label-for="creation_date_end">
                      <BFormInput id="creation_date_end" type="date" v-model="creation_date_end" />
                    </BFormGroup>
                  </div>
                </div>
              </BFormGroup>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <BFormGroup :label="t('advanced_search.format.label')" class="mt-3">
                <BFormCheckbox v-model="format_epub" @update:model-value="toggle_epub">{{ t('advanced_search.format.epub') }}</BFormCheckbox>
                <BFormCheckboxGroup v-model="epub_options" stacked class="ms-3">
                  <BFormCheckbox value="reflowable">{{ t('advanced_search.format.reflowable') }}</BFormCheckbox>
                  <BFormCheckbox value="fixed_layout">{{ t('advanced_search.format.fixed_layout') }}</BFormCheckbox>
                </BFormCheckboxGroup>
                <BFormCheckbox v-model="format_pdf">{{ t('advanced_search.format.pdf') }}</BFormCheckbox>
                <BFormCheckbox v-model="format_audiobook" @update:model-value="toggle_audiobook">{{ t('advanced_search.format.audiobook') }}</BFormCheckbox>
                <BFormCheckboxGroup v-model="audiobook_options" stacked class="ms-3">
                  <BFormCheckbox value="unabridged">{{ t('advanced_search.format.unabridged') }}</BFormCheckbox>
                  <BFormCheckbox value="abridged">{{ t('advanced_search.format.abridged') }}</BFormCheckbox>
                </BFormCheckboxGroup>
              </BFormGroup>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <BFormGroup :label="t('advanced_search.catalog.label')" class="mt-3">
                    <BFormCheckboxGroup v-model="catalogs" stacked>
                      <BFormCheckbox value="traditional">{{ t('advanced_search.catalog.traditional') }}</BFormCheckbox>
                      <BFormCheckbox value="self_published">{{ t('advanced_search.catalog.self_published') }}</BFormCheckbox>
                    </BFormCheckboxGroup>
                  </BFormGroup>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <BFormGroup :label="t('advanced_search.accessibility.label')" class="mt-3">
                    <BFormRadio v-model="accessibility" name="accessibility" value="all">{{ t('advanced_search.accessibility.all') }}</BFormRadio>
                    <BFormRadio v-model="accessibility" name="accessibility" value="accessible">{{ t('advanced_search.accessibility.accessible') }}</BFormRadio>
                    <BFormRadioGroup v-model="accessibility_options" stacked class="ms-3">
                      <BFormRadio value="accessibility_fully" :disabled="accessibility_options_disabled">{{ t('advanced_search.accessibility.accessibility_fully') }}</BFormRadio>
                      <BFormRadio value="accessibility_textual" :disabled="accessibility_options_disabled">{{ t('advanced_search.accessibility.accessibility_textual') }}</BFormRadio>
                      <BFormRadio value="accessibility_auditory" :disabled="accessibility_options_disabled">{{ t('advanced_search.accessibility.accessibility_auditory') }}</BFormRadio>
                      <BFormRadio value="accessibility_synchronized" :disabled="accessibility_options_disabled">{{ t('advanced_search.accessibility.accessibility_synchronized') }}</BFormRadio>
                    </BFormRadioGroup>
                  </BFormGroup>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <BButton type="submit" variant="primary">{{ t('advanced_search.submit') }}</BButton>
          </div>
        </BForm>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  :deep() {
    @import '../../../node_modules/bootstrap/scss/bootstrap.scss';
    @import '../../../node_modules/bootstrap-vue-next/dist/bootstrap-vue-next';
  }

  input[type="date"]:before {
    display: none !important;
  }

  input[type="date"]:focus:before {
    display: none !important;
  }
</style>

<i18n src="../locales/en.json" global></i18n>
<i18n src="../locales/fr.json" global></i18n>
<i18n src="../locales/es.json" global></i18n>
<i18n src="../locales/it.json" global></i18n>
<i18n src="../locales/pt.json" global></i18n>
