import { defineStore } from "pinia";
import { capitalize } from "vue";
import { useI18n } from "vue-i18n";

export const useTableColumnStore = defineStore("TableColumnStore", {
  state: () => ({
    allColumns: [
      { key: 'id', sortable: false, alwaysVisible: true },
      { key: 'cover', sortable: false, alwaysVisible: true },
      { key: 'title', sortable: true, alwaysVisible: true },
      { key: 'publication', sortable: true },
      { key: 'author', sortable: true },
      { key: 'publisher', sortable: true },
      { key: 'collection', sortable: true },
      { key: 'category', sortable: true },
      { key: 'influence', sortable: true },
      { key: 'isbn', sortable: true },
      { key: 'alternate_isbn', sortable: true },
      { key: 'last_updated', sortable: true },
      { key: 'book_format' },
      { key: 'lists' },
      { key: 'language' },
      { key: 'series' },
      { key: 'from_quebec' },
      { key: 'description' },
      { key: 'internal_id' },
      { key: 'provider', sortable: true },
      { key: 'offers' },
      { key: 'audience'},
      { key: 'publishing_group', sortable: true },
      { key: 'catalog_date', sortable: true },
      { key: 'catalog_type' },
      { key: 'status' },
      { key: 'accessibility_features' },
      { key: 'awards' }
    ],
    activeColumns: [
      'id',
      'cover',
      'title',
      'publication',
      'author',
      'publisher',
      'collection',
      'category',
      'influence',
      'isbn',
      'alternate_isbn',
      'last_updated',
      'book_format',
      'language',
      'lists',
      'series',
      'from_quebec',
      'description',
      'internal_id',
      'provider',
      'offers',
      'audience',
      'publishing_group',
      'catalog_date',
      'catalog_type',
      'status',
      'accessibility_features',
      'awards'
    ],
    inactiveColumns: [],
    orderColumns: [
      'id',
      'cover',
      'title',
      'publication',
      'author',
      'publisher',
      'collection',
      'category',
      'influence',
      'isbn',
      'alternate_isbn',
      'last_updated',
      'book_format',
      'language',
      'lists',
      'series',
      'from_quebec',
      'description',
      'internal_id',
      'provider',
      'offers',
      'audience',
      'publishing_group',
      'catalog_date',
      'catalog_type',
      'status',
      'accessibility_features',
      'awards'
    ],

    filters: [
      { key: 'publication_date' },
      { key: 'catalog_date' },
      { key: 'provider' },
      { key: 'language' },
      { key: 'status' },
      { key: 'book_format' },
      { key: 'category' },
      { key: 'influence' },
      { key: 'audience' },
      { key: 'author' },
      { key: 'imprint' },
      { key: 'collection' },
      { key: 'catalog_type' },
      { key: 'title_subtitle' },
      { key: 'isbn' },
      { key: 'from_quebec' },
      { key: 'cover' },
      { key: 'last_update' },
      { key: 'offer_id' },
      { key: 'publishing_group' },
      { key: 'series' },
      { key: 'list' },
      { key: 'award' },
      { key: 'accessibilities' },
    ],
    activeFilters: [
      'publication_date',
      'catalog_date',
      'provider',
      'language',
      'status',
      'book_format',
      'category',
      'influence',
      'audience',
      'author',
      'imprint',
      'collection',
      'catalog_type',
      'title_subtitle',
      'isbn',
      'from_quebec',
      'cover',
      'last_update',
      'offer_id',
      'publishing_group',
      'series',
      'list',
      'award',
      'accessibilities'
    ],
    inactiveFilters: [],
    orderFilters: [
      'publication_date',
      'catalog_date',
      'provider',
      'language',
      'status',
      'book_format',
      'category',
      'influence',
      'audience',
      'author',
      'imprint',
      'collection',
      'catalog_type',
      'title_subtitle',
      'isbn',
      'from_quebec',
      'cover',
      'last_update',
      'offer_id',
      'publishing_group',
      'series',
      'list',
      'award',
      'accessibilities'
    ]
  }),
  getters: {
    getColumnsCanBeHidden() {
      return this.columns.filter(column => !column.alwaysVisible);
    },
    getFiltersCanBeHidden() {
      return this.filters.filter(filter => !filter.alwaysVisible);
    },
    getActiveFilters() {
      return this.activeFilters;
    },
    columns() {
      const { t } = useI18n();
      return this.allColumns.map(column => {
        column.label = t(`components.catalog_publication.table.columns.${column.key}`);
        return column;
      });
    }
  },
  actions: {
    removeOrAdd(item, type) {
      const active = `active${capitalize(type)}`;
      const inactive = `inactive${capitalize(type)}`;
      if(this[active].includes(item.key)) {
        const index = this[active].indexOf(item.key);
        this[active].splice(index, 1);
        this[inactive].push(item.key);
      } else {
        const index = this[inactive].indexOf(item.key);
        this[inactive].splice(index, 1);
        this[active].push(item.key);
      }
    },

    getOriginals(type) {
      const originals = this[type].map(item => item.key);
      return originals;
    },

    changePositions(item, newPosition, type) {
      const active = `active${capitalize(type)}`;
      const order = `order${capitalize(type)}`;
      const index = this[order].indexOf(item);
      this[order].splice(newPosition, 0, this[order].splice(index, 1)[0]);
    },

    setOrder(newOrder, type) {
      const prefix = capitalize(type);
      const active = `active${prefix}`;
      const inactive = `inactive${prefix}`;
      const order = `order${prefix}`;
      this[order] = newOrder;
      this[active] = newOrder;
      const all = this.filters.map(filter => filter.key);
      const remaining = all.filter(filter => !this[active].includes(filter));
      this[inactive] = remaining;

      localStorage.setItem(`order${prefix}`, JSON.stringify(this[order]));
      localStorage.setItem(`active${prefix}`, JSON.stringify(this[active]));
      localStorage.setItem(`inactive${prefix}`, JSON.stringify(this[inactive]));
    },

    applyOrderAndVisibility(type) {
      const active = `active${capitalize(type)}`;
      const order = `order${capitalize(type)}`;
      const newOrder = this[order].filter((column) => {
        return this[active].includes(column) ? column : null;
      });

      this[active].splice(0, this[active].length);
      this[active].push(...newOrder.filter(Boolean));
    }

  }
});
