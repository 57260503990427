export default function (Component) {
  Component.__i18nGlobal = Component.__i18nGlobal || []
  Component.__i18nGlobal.push({
    "locale": "",
    "resource": {
      "es": {
        "search": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanzada"])},
          "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
          "object_types": {
            "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colección"])},
            "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editorial"])},
            "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
            "contributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
            "award": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premio"])},
            "store_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libro"])}
          }
        },
        "book_format": {
          "name": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
            "epub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPUB"])},
            "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
            "audiobook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiolibro"])},
            "mp3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiolibro"])},
            "epub_fixed_layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPUB (tamaño de imagen fijo)"])}
          }
        },
        "offers": {
          "other_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras licencias para esta publicación"])},
          "incompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your lending portal is incompatible with this publication"])},
          "previous_purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more licenses for this book have been acquired by your organisation"])},
          "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This book is included in the shared collection"])},
          "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
          "more_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de détails"])},
          "fewer_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins de détails"])},
          "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disable"])},
          "disable_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are-you sure you want to disable this offer? This operation cannot be reversed. It's also possible this offer will be enabled again following a future reimportation."])},
          "admin": {
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprimir artículo de la cesta"])},
            "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intentar comandar este artículo nuevamente"])},
            "current_price": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Precio actual de la oferta ", _interpolate(_named("price"))])},
            "new_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo precio"])},
            "update_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización"])},
            "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar la oferta"])}
          },
          "carts": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre du usuario"])},
            "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
            "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended Cart"])},
            "disclaimer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These recommendations are made by"])},
            "disclaimer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They can include **popular books in your library** that need their license renewed, **books that are not in your catalog** that might interest readers based on their preferences, books **popular in other libraries**, but not in your catalog."])},
            "banner_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 easy ways to improve your catalog"])},
            "banner_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover baskets already created to make your life easier. In these baskets are books that are:"])},
            "banner_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. The most popular in your library whose license needs to be renewed"])},
            "banner_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Absent from your catalog that might interest your readers based on their preferences"])},
            "banner_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Popular in other libraries, but are not in your catalog"])},
            "customer_service_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service Cart"])},
            "customer_service_text_html": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This cart was validated by our Customer Success team. ", _interpolate(_named("br")), " Copies for all titles below are available in your catalog but will not be invoiced to your organization."])}
          },
          "cart_orders": {
            "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libro"])},
            "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado el"])},
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
            "distributor_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuidor de la oferta"])},
            "formatted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formateado"])},
            "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de la cesta"])},
            "order_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de la comanda"])},
            "provider_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distribuidor de la oferta"])},
            "provider_publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeur de la publication"])},
            "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros de la búsqueda"])},
            "raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruto"])},
            "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos de cestas de la biblioteca"])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
            "url_unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown URL"])}
          }
        },
        "cart": {
          "refund": {
            "reason": {
              "faulty_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faulty files"])},
              "ordered_for_the_wrong_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered for the wrong library"])},
              "technical_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical issue"])},
              "operational_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational error"])},
              "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])}
            },
            "select_a_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a reason"])},
            "refund_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund mode"])},
            "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
            "button_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
            "confirm_window": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm refund"])},
              "table": {
                "unit_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit price"])},
                "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
                "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
                "refund_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund amount"])},
                "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])}
              },
              "form": {
                "label_adicional_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details to include in client notification email (optional)"])},
                "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevant context for clients (library, network, retailer, etc.)"])},
                "button_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
                "button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm refund"])}
              }
            },
            "refund_copies": {
              "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Refunded Copy"]), _normalize(["Refunded Copies"])])},
              "message_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download refund summary"])},
              "date_of_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of refund"])}
            },
            "refund_cofirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund confirmed"])}
          },
          "confirm_validation": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As all sales are **final and non-refundable**, please take a moment to make sure this cart perfectly matches what you want to buy."])},
            "particularly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particularly"])},
            "list": {
              "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prices"])},
              "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantities"])},
              "formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formats"])},
              "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["license types"])}
            },
            "checkbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand and confirm that all items in my order are in accordance with my intent to purchase."])}
          },
          "license": {
            "no_other_license_type_is_available_for_this_publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ningún otro tipo de licencia disponible para esta publicación"])},
            "select_the_desired_license_type_and_click_the_replace_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el tipo de licencia deseado y haz clic sobre el botón Remplazar"])},
            "see_other_available_license_types_for_this_publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver otros tipos de licencia disponibles para esta publicación"])}
          },
          "move_cart": {
            "available_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliotecas disponibles"])},
            "available_library_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios de la biblioteca"])},
            "confirm_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de querer desplazar estos artículos a esta cesta?"])},
            "manage_users": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay un usuario asociado a esta biblioteca"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionar los usuarios"])}
            },
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir a otra biblioteca"])}
          },
          "retailer": {
            "retailers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuidores"])}
          },
          "sub_library": {
            "sub_libraries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturación avanzada"])}
          },
          "item_status": {
            "already_purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta ya adquirida"])},
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
            "in_another_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta ya presente en otra cesta"])},
            "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rembolsado"])},
            "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validado"])}
          },
          "unavailable_offers": {
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos un título contenido en la cesta ya no está disponible: sus condiciones de venta han sido modificadas después de haberlo agregado."])},
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, elimine de su cesta el o los siguientes título a fin de continuar con la validación:"])},
            "delete_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprimir estos títulos"])},
            "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimos los inconvenientes."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información importante sobre su cesta"])},
            "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
            "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])}
          },
          "incompatible_offers": {
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service carts may only contain titles coming from a source operated by De Marque."])},
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must remove the following titles from the cart before it can be validated:"])},
            "delete_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar estos títulos"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart validation failed"])}
          },
          "loans": {
            "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 préstamo"])},
            "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " préstamos"])},
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préstamos ilimitados"])},
            "loan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 préstamo"]), _normalize([_interpolate(_named("count")), " préstamos"])])}
          },
          "loans_concurrent_users": {
            "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno a la vez"])},
            "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " simultáneos"])},
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilimitados"])}
          },
          "loan_rules": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones de préstamo"])},
            "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de préstamos"])},
            "concurrent_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préstamos simultáneos"])},
            "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la licencia"])},
            "max_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración máxima de un préstamo"])}
          },
          "loans_lifetime": {
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duración ilimitada"])}
          },
          "lifetime": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lifetime"])},
            "name": {
              "one_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One week"])},
              "one_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un mes"])},
              "two_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two months"])},
              "one_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un año"])},
              "two_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dos años"])},
              "two_to_five_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De dos a cinco años"])},
              "five_to_ten_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De cinco a diez años"])},
              "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilimitada"])}
            }
          },
          "datetime": {
            "distance_in_words": {
              "half_a_minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medio minuto"])},
              "less_than_x_seconds": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menos de 1 segundo"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["menos de ", _interpolate(_named("count")), " segundos"])}
              },
              "x_seconds": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 segundo"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " segundos"])}
              },
              "less_than_x_minutes": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menos de 1 minuto"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["menos de ", _interpolate(_named("count")), " minutos"])}
              },
              "x_minutes": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 minuto"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " minutos"])}
              },
              "about_x_hours": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alrededor de 1 hora"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["alrededor de ", _interpolate(_named("count")), " horas"])}
              },
              "x_days": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 día"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " días"])}
              },
              "about_x_months": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alrededor de 1 mes"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["alrededor de ", _interpolate(_named("count")), " meses"])}
              },
              "x_months": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mes"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " meses"])}
              },
              "about_x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alrededor de 1 año"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["alrededor de ", _interpolate(_named("count")), " años"])}
              },
              "over_x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["más de 1 año"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["más de ", _interpolate(_named("count")), " años"])}
              },
              "almost_x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["casi 1 año"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["casi ", _interpolate(_named("count")), " años"])}
              },
              "x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 año"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " años"])}
              },
              "x_years_or_more": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un año o más"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " años o más"])}
              },
              "x_years_and_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " años y ", _interpolate(_named("days")), " días"])}
            }
          },
          "status": {
            "already_purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta ya comprada"])},
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fracaso"])},
            "in_another_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta ya presente en otro carrito"])},
            "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso"])},
            "to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso"])},
            "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validada"])}
          },
          "copies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("copies")), " copias"])},
          "lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("lines")), " líneas"])},
          "copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("copies")), " copia"])},
          "line": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("lines")), " línea"])},
          "cart_item": {
            "status": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
              "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallido"])},
              "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo"])},
              "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
              "to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validado"])}
            }
          },
          "total_with_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Todos los impuestos incluidos"])},
          "cart_validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar la cesta"])},
          "back_button_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis cestas"])},
          "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
          "cart_destroy_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres suprimir este carrito?"])},
          "cart_reprocess_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reprocess this cart?"])},
          "actions": {
            "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar"])},
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprimir la cesta"])},
            "delete_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprimir"])},
            "export_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar en formato CSV"])},
            "export_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar en formato Excel"])},
            "import_isbns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar los ISBN"])},
            "move_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])},
            "move_items_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazar los artículos"])},
            "reprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar la cesta nuevamente"])},
            "duplicate_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar la cesta"])},
            "resend_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar el correo de confirmación"])},
            "show_processing_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar los logs del proceso"])},
            "process_a_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process a refund"])},
            "show_cart_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart history"])}
          },
          "paper_isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN de papel:"])},
          "offer_isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN de la oferta:"])},
          "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
          "offer": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de usuario"])}
          },
          "form": {
            "move_items": {
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las ofertas seleccionadas han sido desplazadas a la cesta siguiente"])},
              "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected offers have been copied to the following cart"])}
            },
            "destination_cart_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva cesta"])},
            "current_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cestas existentes"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
            "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
            "libraries_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cestas de la biblioteca"])},
            "my_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis certas"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
          },
          "header": {
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprimir"])},
            "move_items_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazar los artículos"])},
            "copy_items_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
            "select_destination_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar una cesta de destino"])},
            "sort_by_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por"])},
            "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea suprimir los artículos seleccionados?"])},
            "labels": {
              "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libro / oferta"])},
              "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio unitario"])},
              "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
              "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de publicación"])},
              "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
              "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])}
            },
            "sort": {
              "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
              "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato"])},
              "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de publicación"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
              "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
            }
          },
          "cart_item_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
          "cart_item_details_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la oferta"])},
          "cart_item_details_modal_ownership_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ownership model"])},
          "cart_item_details_modal_ownership_text_html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This offer is not a license. The purchasing library owns the digital copy of the title they acquire. To review the rights and responsibilities that ownership confers, please see these <a href=\"https://ebooks.dp.la/digital-ownership-agreement/\" target=\"_blank\">detailed terms of the DPLA Ownership model</a>. To learn more, please consult these <a href=\"https://ebooks.dp.la/ownership/\" target=\"_blank\">Frequently Asked Questions</a>."])},
          "cart_item_details_modal_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato"])},
          "cart_item_details_modal_file_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de archivo"])},
          "cart_item_details_modal_loan_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas de préstamo"])},
          "cart_item_details_modal_loan_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de préstamos"])},
          "cart_item_details_modal_concurrent_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préstamos simultáneos"])},
          "cart_item_details_modal_license_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la licencia"])},
          "cart_item_details_modal_license_duration_for_ownership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer duration"])},
          "cart_item_details_modal_license_unlimited_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unlimited duration"])},
          "cart_item_details_modal_loan_maximum_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración máxima del préstamo"])},
          "cart_item_details_modal_streaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streaming"])},
          "cart_item_details_modal_streaming_concurrent_users_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de préstamos simultáneos"])},
          "cart_item_details_modal_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protección"])},
          "cart_item_details_modal_protection_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de protección"])},
          "cart_item_details_modal_authorized_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periféricos autorizados"])},
          "cart_item_details_modal_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar/pegar"])},
          "cart_item_details_modal_print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impresión"])},
          "ownership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ownership"])},
          "there_is_no_offer_in_this_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay artículos en su cesta."])},
          "carts": {
            "group_purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este libro está incluido en la colección compartida"])},
            "list_items": {
              "ajax_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, algo salió mal. Vamos a actualizar la página para que puedas intentarlo de nuevo"])},
              "confirm_delete_titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de querer suprimir estos títulos?"])},
              "one_title_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes seleccionar al menos un título a suprimir."])}
            },
            "lists": {
              "titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titles"])}
            },
            "currencies": {
              "CAD": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " $ CAD"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$ CAD"])}
              },
              "CHF": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " CHF"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHF"])}
              },
              "EUR": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " €"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])}
              },
              "GBP": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " £"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["£"])}
              },
              "USD": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount"))])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])}
              },
              "ARS": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " $ ARS"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$ ARS"])}
              }
            },
            "details": {
              "loans_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 préstamo"])},
              "loans_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " préstamos"])},
              "loans_unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préstamos ilimitados"])},
              "loans_concurrent_users_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno a la vez"])},
              "loans_concurrent_users_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " simultáneos"])},
              "loans_concurrent_users_unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilimitados"])},
              "loans_lifetime_unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duración ilimitada"])},
              "x_days_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 día"])},
              "x_days_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " días"])},
              "x_years_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 año"])},
              "x_years_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " años"])},
              "denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falso"])},
              "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilimitadas"])}
            },
            "service_fees": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cargos de acceso y seguro para los libros digitales y audiolibros (", _interpolate(_named("rate")), "%)"])},
            "sub_total_without_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal sin impuestos"])},
            "total_by_currency": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total ", _interpolate(_named("currency"))])},
            "total_without_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total sin IVA"])},
            "tax_rates": {
              "gst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GST"])},
              "hst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HST"])},
              "pst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PST"])},
              "qst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QST"])},
              "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA"])}
            }
          }
        },
        "not_found": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are looking for does not exist."])}
        },
        "components": {
          "accessibility_features_details": {
            "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accessibility features ", _interpolate(_named("format"))])},
            "license_accessibility": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("book_format")), " licenses accessibility"])},
            "accessibility_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibility features"])},
            "accessibility_other_fh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other features and hazards"])},
            "no_accessibility_data_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No accessibility data available"])}
          },
          "offer_details": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer details"])},
            "group": {
              "format": {
                "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
                "columns": {
                  "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
                  "book_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File type"])}
                }
              },
              "loan_rules": {
                "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan rules"])},
                "columns": {
                  "max_loan_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum loan duration"])},
                  "concurrent_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed concurrent users"])},
                  "lifetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lifetime"])},
                  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan count"])}
                }
              },
              "streaming_mode": {
                "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streaming"])},
                "columns": {
                  "streaming_concurrent_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concurrent users count"])},
                  "loans_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan count"])},
                  "protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection"])}
                }
              },
              "protection": {
                "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection"])},
                "columns": {
                  "protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection type"])},
                  "max_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized devices"])},
                  "copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy/paste"])},
                  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])}
                }
              }
            }
          },
          "title_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
          "default_message_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Something went wrong. Please try again in a moment."])},
          "filtrable_select": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar opciones"])},
            "tag_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga Enter para crear una etiqueta"])},
            "select_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic para seleccionar"])},
            "select_group_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["haga clic para seleccionar grupo"])},
            "selected_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección"])},
            "deselect_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presione enter para deseleccionar"])},
            "deselect_group_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presione enter para deseleccionar grupo"])}
          },
          "catalog_publication": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catálogo de publicaciones"])},
            "saved_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuraciones"])},
            "access_all_saved_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder a todas las configuraciones guardadas"])},
            "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
            "subtitles": {
              "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
              "title_characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características del título"])},
              "classification_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación y tema"])},
              "publication_characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características de la publicación"])},
              "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catálogo"])}
            },
            "settings_page": {
              "loading_creators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading creators..."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Settings"])},
              "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
              "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Settings"])},
              "messages": {
                "destroy_settings": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
                  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings successfully deleted."])}
                }
              },
              "modal": {
                "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remove ", _interpolate(_named("name"))])},
                "confirm_delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this settings? This action cannot be undone."])},
                "button": {
                  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
                  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
                }
              },
              "role": {
                "name": {
                  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
                  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
                  "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
                  "librarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Librarian"])},
                  "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
                  "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])}
                }
              },
              "filters": {
                "options": {
                  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
                },
                "select": {
                  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
                  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
                  "per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per page"])}
                },
                "input": {
                  "search": {
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name or creator"])},
                    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
                  }
                },
                "button": {
                  "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])}
                }
              },
              "table": {
                "column": {
                  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
                  "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
                  "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
                  "general_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
                  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
                },
                "button": {
                  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
                  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
                  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])}
                }
              }
            },
            "isbn_form": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new ISBNs separated by a space, comma or semicolon"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBNs"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inject ISBNs"])},
              "addButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
              "duplicateTagText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate ISBN"])},
              "limitTagsText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Limit reached (max. ", _interpolate(_named("maxTags")), ")"])},
              "buttons": {
                "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
                "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])}
              }
            },
            "isbn_results": {
              "result_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("of")), "/", _interpolate(_named("from")), " ISBNs found"])},
              "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ISBNs found"])},
              "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
              "items_not_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("items")), " not found"])},
              "copy_not_found_isbns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy ISBNs that cannot be found"])}
            },
            "item_actions": {
              "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
              "resynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La resincronización se ha puesto en cola. Espere unos minutos para que el servidor procese la solicitud."])},
              "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página"])},
              "out_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de un total de"])},
              "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados"])},
              "lines_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líneas seleccionadas"])},
              "clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar la selección"])},
              "search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados de la búsqueda"])},
              "show_full_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar la consulta completa"])},
              "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementos"])},
              "dropdown_export_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar resultados"])},
              "dropdown_inject_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inyectar lista de ISBN"])},
              "dropdown_add_selection_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir selección a una lista"])},
              "dropdown_apply_metadata_to_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar metadatos a la selección"])},
              "dropdown_synchronize_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar selección con metadatos del almacén"])}
            },
            "availabilities": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilidad"])},
              "customize_availability_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar la disponibilidad"])},
              "networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redes"])},
              "territories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territorio"])},
              "markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
              "connectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conector"])},
              "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninguno"])},
              "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por defecto"])},
              "all_networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las redes"])},
              "all_territories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los territorios"])},
              "all_markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los mercados"])},
              "all_connectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los conectores"])},
              "all_audiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las audiencias"])},
              "all_formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los formatos"])},
              "all_accessibilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las accesibilidades"])},
              "all_providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los proveedores"])},
              "all_catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los tipos de catálogo"])}
            },
            "filters": {
              "libraries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catálogo de biblioteca"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
              "catalog_date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catálogo (Desde)"])},
              "catalog_date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catálogo (Hasta)"])},
              "publication_date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicación (Desde)"])},
              "publication_date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicación (Hasta)"])},
              "last_updated_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización (Desde)"])},
              "last_updated_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización (Hasta)"])},
              "publication_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de publicación (desde - hasta)"])},
              "catalog_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de catálogo (desde - hasta)"])},
              "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última actualización (desde - hasta)"])},
              "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedor"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenguaje"])},
              "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilidad"])},
              "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
              "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No disponible"])},
              "unavailable__publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor"])},
              "unavailable__banned_not_epub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohibido (no EPUB)"])},
              "unavailable__banned_publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohibido (editor)"])},
              "unavailable__date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
              "unavailable__cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubierta"])},
              "unavailable__duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicado"])},
              "book_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato"])},
              "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
              "influence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influencia"])},
              "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiencia"])},
              "title_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título y subtítulo"])},
              "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
              "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor"])},
              "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colección"])},
              "type_to_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe para buscar"])},
              "save_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar ajustes"])},
              "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer filtros"])},
              "serie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
              "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
              "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninguno"])},
              "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por defecto"])},
              "option_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
              "option_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
              "pick_several_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-selección"])},
              "market_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliotecas públicas"])},
              "market_retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuidor"])},
              "market_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escuela"])},
              "market_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aula"])},
              "market_academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universidades"])},
              "market_corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresas"])},
              "catalog_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de catálogo"])},
              "catalog_type_traditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicación tradicional"])},
              "catalog_type_self_published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-publicación"])},
              "catalog_type_public_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominio público"])},
              "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
              "offer_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de la oferta"])},
              "publishing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo editorial"])},
              "last_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última actualización"])},
              "accessibility_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros de accesibilidad"])},
              "accessibilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesibilidad"])},
              "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
              "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista curada"])},
              "award": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premio"])},
              "from_quebec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor de Québec"])},
              "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubierta?"])},
              "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin resultados"])},
              "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesibilidad"])},
              "fully_accessible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completamente accesible"])},
              "screen_reader_friendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideal para los lectores de pantallas"])},
              "full_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio integral"])},
              "synchronized_text_and_audio_playback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lectura sincronizada de texto y audio"])},
              "sorter_columns_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambie las columnas visibles"])},
              "sorter_filters_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambie los filtros visibles"])},
              "sorter_description_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic en los botones de abajo para ocultar o mostrar las columnas correspondientes."])},
              "sorter_description_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic en los botones de abajo para ocultar o mostrar los filtros correspondientes."])},
              "sorter_description_drag_and_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después de hacer clic en uno de los botones, puedes arrastrarlos para cambiar su posición."])},
              "sorter_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
              "sorter_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
              "sorter_change_columns_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar las columnas"])},
              "sorter_change_filters_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar los filtros"])},
              "sorter_reset_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer las columnas"])},
              "sorter_change_position_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar la posición de las columnas"])},
              "sorter_reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer la visibilidad y posición de los filtros"])},
              "sorter_change_position_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar la posición de los filtros"])}
            },
            "save_settings": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar ajustes"])},
              "visible_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros visibles"])},
              "filter_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valores de los filtros"])},
              "save_filter_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar los valores actuales de los filtros"])},
              "change_filter_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar los valores de los filtros"])},
              "visible_values_in_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valores visibles en los resultados"])},
              "saved_settings_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de los ajustes guardados"])},
              "saved_settings_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de ajustes guardados"])},
              "update_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar los ajustes"])},
              "personal_settings_only_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo tú tendrás acceso"])},
              "personal_settings_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los usuarios con tu rol"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "error_unable_to_save_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pueden guardar los ajustes"])},
              "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar"])},
              "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprimir"])},
              "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de querer borrar estos ajustes?"])}
            },
            "table": {
              "conditional": {
                "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
                "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
              },
              "columns": {
                "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
                "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover"])},
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title & Subtitle"])},
                "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication date"])},
                "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author(s)"])},
                "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
                "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
                "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
                "influence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influences"])},
                "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
                "alternate_isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper ISBN"])},
                "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last updated"])},
                "book_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
                "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lists"])},
                "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
                "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series"])},
                "from_quebec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Quebec"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
                "internal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal ID"])},
                "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider"])},
                "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers"])},
                "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiencia"])},
                "publishing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo editorial"])},
                "catalog_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de ingreso al catálogo"])},
                "catalog_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de catálogo"])},
                "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado +  Motivo de la no disponibilidad"])},
                "accessibility_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibility features"])},
                "awards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awards"])}
              },
              "language": {
                "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
                "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
                "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
                "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
                "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
                "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
                "ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalan"])},
                "eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basque"])},
                "gl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galician"])},
                "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
                "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])}
              }
            }
          },
          "modal": {
            "button": {
              "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
            }
          }
        },
        "store_item_export": {
          "component": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export results"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select / unselect the values in the following list to define which colums and values will be in the export."])},
            "export_buttons_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select, using the buttons below, the columns you want to export."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Values to export"])},
            "buttons": {
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
              "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
            }
          },
          "request_export": {
            "message": {
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report is being generated. You will receive an email when it is ready."])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while processing the request, please try again later."])}
            }
          },
          "columns": {
            "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover url"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title & subtitle"])},
            "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication date"])},
            "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author(s)"])},
            "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
            "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "influence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influences"])},
            "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
            "alternate_isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper ISBN"])},
            "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])},
            "book_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
            "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lists"])},
            "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series"])},
            "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
            "from_quebec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Quebec"])},
            "internal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal ID"])},
            "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider"])},
            "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audience"])},
            "publishing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing group"])},
            "catalog_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog date"])},
            "catalog_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog type"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status + Unavailable reason"])},
            "awards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awards"])}
          },
          "is_from_quebec": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
          }
        }
      }
    }
  })
}
