<script setup>
  import { ref } from 'vue';
  import VueMultiselect from 'vue-multiselect';
  import axios from '../../services/ApiService';

  const DEBOUNCE_TIME = 750;
  const MIN_TEXT_LENGTH = 3;

  let debounceRunning = false;
  let timer = null;

  const model = defineModel();
  const props = defineProps({
    object_type: {
      type: String,
      required: true
    },
    inclusion: {
      type: String,
      required: false,
      default: 'include'
    },
    options: {
      type: Array,
      required: false,
      default: []
    }
  });
  const isLoading = ref(false);
  const options = ref(props.options);

  const debounce = function(text) {
    if (debounceRunning || text.length < MIN_TEXT_LENGTH) return Promise.resolve(false);

    debounceRunning = true;
    return new Promise((resolve) => {
      if (timer === null) {
        timer = setTimeout(() => {
          clearTimeout(timer);
          timer = null;
          debounceRunning = false;
          resolve(true);
        }, DEBOUNCE_TIME);
      }
    });
  }

  const asyncFind = async (query) => {
    if (props.options.length > 0) {
      return;
    }

    debounce(query).then(async (valid) => {
      if (!valid) {
        options.value = [];
        return;
      }

      fetchSuggestions(query);
    });
  };

  const fetchSuggestions = async (query) => {
    isLoading.value = true;
    const response = await axios.get(`/api/suggests/filter.json?q=${query}&type=${props.object_type}`);
    options.value = response.data;
    isLoading.value = false;
  }
</script>

<template>
  <div :class="[props.inclusion]">
    <VueMultiselect
      :id="`${props.object_type}_${props.inclusion}`"
      v-model="model"
      :options="options"
      :label="'name'"
      :track-by="'id'"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :hide-selected="true"
      :internal-search="props.options.length !== 0"
      :loading="isLoading"
      @search-change="asyncFind"
      :show-no-options="false"
      :show-no-results="false"
      :placeholder="''">
    </VueMultiselect>
  </div>
</template>

<style lang="scss">
  .multiselect__tags {
    border: none;
  }

  .include {
    .multiselect__tag,
    .multiselect__option--highlight {
      background-color: #27AE60;
    }
  }

  .exclude {
    .multiselect__tag,
    .multiselect__option--highlight {
      background-color: #C0392B;
    }
  }

  .multiselect__tag-icon::after {
    color: white;
  }
</style>

<style scoped lang="scss">

</style>
