<script setup>
  import { useI18n } from 'vue-i18n';
  import { BFormGroup } from 'bootstrap-vue-next';
  import SuggestionBox from './SuggestionBox.vue';

  const { t } = useI18n();

  const props = defineProps({
    object_type: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: false,
      default: []
    }
  });

  const inclusions = defineModel('inclusions');
  const exclusions = defineModel('exclusions');
</script>

<template>
  <BFormGroup :label="t(`advanced_search.${props.object_type}.label`)" class="mt-3" :label-for="`${props.object_type}_include`">
    <div class="row">
      <div class="col-6">
        <BFormGroup :label="t('advanced_search.include')" :label-for="`${props.object_type}_include`">
          <SuggestionBox v-model="inclusions" :object_type="props.object_type" :inclusion="'include'" :options="props.options" />
        </BFormGroup>
      </div>
      <div class="col-6">
        <BFormGroup :label="t('advanced_search.exclude')" :label-for="`${props.object_type}_exclude`">
          <SuggestionBox v-model="exclusions" :object_type="props.object_type" :inclusion="'exclude'" :options="props.options" />
        </BFormGroup>
      </div>
    </div>
  </BFormGroup>
</template>

<style scoped lang="scss">
  :deep() {
    @import '../../../../node_modules/bootstrap/scss/bootstrap.scss';
    @import '../../../../node_modules/bootstrap-vue-next/dist/bootstrap-vue-next';
  }
</style>

<i18n src="../../locales/en.json" global></i18n>
<i18n src="../../locales/fr.json" global></i18n>
<i18n src="../../locales/es.json" global></i18n>
<i18n src="../../locales/it.json" global></i18n>
<i18n src="../../locales/pt.json" global></i18n>
